import style from './Error.style';

export default function Error({
  code,
  message,
}) {
  return (
    <div css={ style }>
      <p>
        <strong>{ code }</strong>
        <span>{ message }</span>
      </p>
    </div>
  );
};