import { Global } from '@emotion/react';
import style from './StaticPage.style';

export default function StaticPage({ children }) {
  return (
    <>
      <Global styles={ style } />
      { children }
    </>
  );
};