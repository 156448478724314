import { css } from '@emotion/react';

export default css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  padding: 1rem;

  > p {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    font-size: 1.5rem;
    
    > strong {
      padding-right: 2rem;
      border-right: 1px solid #ccc;
      font-size: 2rem;
      font-family: var(--font-family-display);
    }
    > span {
      padding-left: 2rem;
    }
  }
`;